import { ValidationTypes } from "enums/validationTypes";
import TranslationMapper from "i18n/mapper";
import INotification from "interfaces/INotification";
import LanguageProvider from "providers/languageProvider";
import { NotificationManager } from "react-notifications";
import ManagementService from "services/managementService";
import TelemetryService from "services/telemetryService";
import { ActionTypes } from "store/actionTypes";
import { actionCreator, AppAction } from "store/appAction";

export const fetchedNotificationAllItems = actionCreator<ActionTypes.FETCHED_NOTIFICATION_ALL_ITEMS, INotification[]>(
  ActionTypes.FETCHED_NOTIFICATION_ALL_ITEMS
);

export const setNotificationLoadingDeleteNotification = actionCreator<
  ActionTypes.UPDATE_LOADING_NOTIFICATION_DELETE_NOTIFICATION,
  boolean
>(ActionTypes.UPDATE_LOADING_NOTIFICATION_DELETE_NOTIFICATION);

export const setNotificationLoadingGetAllNotifications = actionCreator<
  ActionTypes.UPDATE_LOADING_NOTIFICATION_GET_ALL_NOTIFICATION,
  boolean
>(ActionTypes.UPDATE_LOADING_NOTIFICATION_GET_ALL_NOTIFICATION);

export const setNotificationLoadingUpsertNotification = actionCreator<
  ActionTypes.UPDATE_LOADING_NOTIFICATION_UPSERT_NOTIFICATION,
  boolean
>(ActionTypes.UPDATE_LOADING_NOTIFICATION_UPSERT_NOTIFICATION);

export function toggleLoadingDeleteNotification(isLoading: boolean): AppAction {
  return (dispatch): void => {
    dispatch(setNotificationLoadingDeleteNotification(isLoading));
  };
}

export function toggleLoadingGetAllNotifications(isLoading: boolean): AppAction {
  return (dispatch): void => {
    dispatch(setNotificationLoadingGetAllNotifications(isLoading));
  };
}

export function toggleLoadingUpsertNotification(isLoading: boolean): AppAction {
  return (dispatch): void => {
    dispatch(setNotificationLoadingUpsertNotification(isLoading));
  };
}

export function deleteNotification(id: string, onSuccess: () => void): AppAction {
  return (dispatch): void => {
    const managementService = new ManagementService();
    dispatch(toggleLoadingDeleteNotification(true));
    managementService
      .deleteNotificationAsync(id)
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "NotificationActions.deleteNotification",
            message: `Error on delete notification item request, error: '${error}'`,
          },
        });
        NotificationManager.error(
          LanguageProvider.t(TranslationMapper.pages.notification.messages.error.delete_notification)
        );
        console.warn(error);
      })
      .finally(() => dispatch(toggleLoadingDeleteNotification(false)));
  };
}

export function getListAllNotifications(): AppAction {
  return (dispatch): void => {
    const managementService = new ManagementService();
    dispatch(toggleLoadingGetAllNotifications(true));
    managementService
      .getAllNotificationsAsync()
      .then((allNotifications) => {
        dispatch(fetchedNotificationAllItems(allNotifications));
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "NotificationActions.getListAllNotifications",
            message: `Error on get list all notifications request, error: '${error}'`,
          },
        });
        NotificationManager.error(
          LanguageProvider.t(TranslationMapper.pages.notification.messages.error.list_all_notifications)
        );
        console.warn(error);
      })
      .finally(() => dispatch(toggleLoadingGetAllNotifications(false)));
  };
}

export function upsertNotification(notification: INotification, onSuccess: () => void): AppAction {
  return (dispatch): void => {
    const managementService = new ManagementService();
    dispatch(toggleLoadingUpsertNotification(true));
    managementService
      .upsertNotificationAsync(notification)
      .then((response) => response.json())
      .then((response) => {
        if (response.isSuccess === false) {
          if (response.propertyErrors) {
            response.propertyErrors.forEach((error) => {
              // Date may not overlap with existing notifications
              if (error.validationType === ValidationTypes.DateOverlap) {
                NotificationManager.error(
                  LanguageProvider.t(TranslationMapper.pages.notification.messages.error.invalid_date_overlap)
                );
              }
            });
          }
        } else {
          onSuccess();
        }
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "NotificationActions.upsertNotification",
            message: `Error on upsert notification item request, error: '${error}'`,
          },
        });
        NotificationManager.error(
          LanguageProvider.t(TranslationMapper.pages.notification.messages.error.upsert_notification)
        );
        console.warn(error);
      })
      .finally(() => dispatch(toggleLoadingUpsertNotification(false)));
  };
}
