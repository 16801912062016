import INotification from "interfaces/INotification";
import IPostalCodeRequest from "interfaces/IPostalCodeRequest";
import IProjectWorkProgram from "interfaces/IProjectWorkProgram";
import IRoomTypeWorkProgram from "interfaces/IRoomTypeWorkProgram";
import IUnitPrice from "interfaces/IUnitPrice";
import IUpsertActiveReqionRequest from "interfaces/IUpsertActiveReqionRequest";
import IWorkProgramAction from "interfaces/IWorkProgramAction";
import IWorkProgramActionFrequency from "interfaces/IWorkProgramActionFrequency";
import { IPatchPostalCodeRequest } from "pages/postalCodeRequest/interfaces/IPatchPostalCodeRequest";
import DateUtils from "utils/dateUtils";
import ManagementClient from "../clients/managementClient";
import IActiveRegion from "../interfaces/IActiveRegion";

export default class ManagementService {
  private readonly managementClient: ManagementClient = new ManagementClient();

  public async getActiveRegionsAsync(): Promise<IActiveRegion[]> {
    return this.managementClient.getActiveRegionsAsync();
  }

  public async deleteActiveRegionAsync(region: number): Promise<void> {
    await this.managementClient.deleteActiveRegionAsync(region);
  }

  public async upsertActiveRegionAsync(request: IUpsertActiveReqionRequest): Promise<void> {
    await this.managementClient.upsertActiveRegionAsync(request);
  }

  public async getPostalCodeRequestsAsync(filter: string): Promise<IPostalCodeRequest[]> {
    return this.managementClient.getPostalCodeRequestsAsync(filter);
  }

  public async patchPostalCodeRequestAsync(id: string, status: string): Promise<Response> {
    return this.managementClient.patchPostalCodeRequestAsync(id, status);
  }

  public async patchPostalCodeRequestsAsync(requests: IPatchPostalCodeRequest[], status: string): Promise<Response> {
    return this.managementClient.patchPostalCodeRequestsAsync(requests, status);
  }

  public async upsertPostalCodeRequestAsync(postalCodeRequest: IPostalCodeRequest): Promise<Response> {
    return this.managementClient.upsertPostalCodeRequestAsync(postalCodeRequest);
  }

  public async deletePostalCodeRequestAsync(id: string): Promise<Response> {
    return this.managementClient.deletePostalCodeRequestAsync(id);
  }

  public async getWorkProgramAsync(externalProjectId: string | undefined): Promise<IProjectWorkProgram> {
    const result = await this.managementClient.getWorkProgramAsync(externalProjectId);
    result.createdOn = DateUtils.utcToLocal(result.createdOn);
    result.modifiedOn = DateUtils.utcToLocal(result.modifiedOn);

    return result;
  }

  public async createProjectWorkProgramAsync(externalProjectId: string): Promise<IProjectWorkProgram> {
    const result = await this.managementClient.createProjectWorkProgramAsync(externalProjectId);
    result.createdOn = DateUtils.utcToLocal(result.createdOn);
    result.modifiedOn = DateUtils.utcToLocal(result.modifiedOn);

    return result;
  }

  public async getActionFrequenciesAsync(): Promise<IWorkProgramActionFrequency[]> {
    const result = await this.managementClient.getActionFrequenciesAsync();
    result.map((freq) => (): void => {
      freq.createdOn = DateUtils.utcToLocal(freq.createdOn);
      freq.modifiedOn = DateUtils.utcToLocal(freq.modifiedOn);
    });

    return result;
  }

  public async removeActionAsync(action: IWorkProgramAction): Promise<Response> {
    return this.managementClient.removeActionAsync(action);
  }

  public async addActionAsync(action: IWorkProgramAction): Promise<IWorkProgramAction> {
    const result = await this.managementClient.addActionAsync(action);
    result.createdOn = DateUtils.utcToLocal(result.createdOn);
    result.modifiedOn = DateUtils.utcToLocal(result.modifiedOn);

    return result;
  }

  public async updateActionAsync(action: IWorkProgramAction): Promise<Response> {
    return this.managementClient.updateActionAsync(action);
  }

  public async addRoomAsync(room: IRoomTypeWorkProgram): Promise<IRoomTypeWorkProgram> {
    const result = await this.managementClient.addRoomAsync(room);
    result.createdOn = DateUtils.utcToLocal(result.createdOn);
    result.modifiedOn = DateUtils.utcToLocal(result.modifiedOn);

    return result;
  }

  public async removeRoomAsync(room: IRoomTypeWorkProgram): Promise<Response> {
    return this.managementClient.removeRoomAsync(room);
  }

  public async getAllUnitPricesAsync(): Promise<IUnitPrice[]> {
    return this.managementClient.getAllUnitPricesAsync();
  }

  public async upsertUnitPriceAsync(unitPrice: IUnitPrice): Promise<void> {
    await this.managementClient.upsertUnitPriceAsync(unitPrice);
  }

  public async deleteUnitPriceAsync(id: string): Promise<Response> {
    return this.managementClient.deleteUnitPriceAsync(id);
  }

  public async deleteNotificationAsync(id: string): Promise<any> {
    return this.managementClient.deleteNotificationAsync(id);
  }

  public async getAllNotificationsAsync(): Promise<any> {
    return this.managementClient.getAllNotificationsAsync();
  }

  public async upsertNotificationAsync(notification: INotification): Promise<any> {
    return this.managementClient.upsertNotificationAsync(notification);
  }
}
