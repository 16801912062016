import { isNullOrEmpty } from "./stringUtils";

// this is the same validator as used in Zizo.App.Registration
export default class InputValidator {
  public static isEmailAddressValid(email?: string): boolean {
    if (!email) {
      return false;
    }

    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(String(email).toLowerCase());
  }

  public static isZipCodeValid(zipCode?: string): boolean {
    const zipCodeRegex = /^[1-9]\d{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
    return zipCodeRegex.test(String(zipCode).toLowerCase());
  }

  public static doesNotContainAmpersand(input?: string): boolean {
    if (!input) {
      return true;
    }

    const ampersandRegex = /&/;
    return !ampersandRegex.test(String(input).toLowerCase());
  }

  public static isPhoneNumberValid(input?: string): boolean {
    if (input == null || isNullOrEmpty(input)) {
      return false;
    }

    const minPhoneNumberLength = 10;
    const cleanedInput = this.cleanPhoneNumber(input);

    return cleanedInput != null && cleanedInput.length >= minPhoneNumberLength;
  }

  public static cleanPhoneNumber(input?: string): string | undefined {
    if (!input) {
      return;
    }

    const allowedInput = /\d/g;
    return input.match(allowedInput)?.join("");
  }
}
