import BasicPopup from "components/basicPopup/basicPopup";
import ConfirmationPrompt from "components/confirmationPrompt/confirmationPrompt";
import Button from "components/material/buttons/button";
import { ButtonStyle } from "components/material/buttons/buttonStyle";
import Page from "components/material/page/page";
import { ReactTable } from "components/material/table/reactTable";
import ReactTableDataUtil from "components/material/table/reactTableDataUtil";
import TranslationMapper from "i18n/mapper";
import DeleteIcon from "images/delete.svg";
import IEmailAddress from "interfaces/IEmailAddress";
import IPostalCodeActivatedEmail from "interfaces/IPostalCodeActivatedEmail";
import IPostalCodeRequest from "interfaces/IPostalCodeRequest";
import LanguageProvider from "providers/languageProvider";
import { ChangeEvent, Component } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { Column, Row as ReactTableRow } from "react-table";
import {
  deletePostalCodeRequest,
  fetchPostalCodeRequests,
  patchPostalCodeRequest,
  patchPostalCodeRequests,
  sendEmail,
  upsertPostalCodeRequest,
} from "store/actions/postalCodeRequestActions";
import { RootState } from "store/reducers/rootReducer";
import DateUtils from "utils/dateUtils";
import InputValidator from "utils/inputValidator";
import { StatusEnum } from "./enums/Status";
import { IPatchPostalCodeRequest } from "./interfaces/IPatchPostalCodeRequest";
import IPostalCodeRequestProps, {
  IPostalCodeRequestDispatchProps,
  IPostalCodeRequestStateProps,
} from "./interfaces/IPostalCodeRequestProps";
import { IPostalCodeRequestState } from "./interfaces/IPostalCodeRequestState";

class PostalCodeRequest extends Component<IPostalCodeRequestProps, IPostalCodeRequestState> {
  private readonly tableDataExampleObject: IPostalCodeRequest = {
    postalCodeRequestId: "",
    postalCode: "",
    city: "",
    name: "",
    status: "",
    emailAddress: "",
    registrationState: "",
    costEmployee: 0,
    costEmployer: 0,
    houseNumber: 0,
    requestDateTime: "",
    totalCosts: 0,
    requestedTime: 0,
    requestedIroningTime: 0,
    totalTime: 0,
  };
  private readonly filterResetValue: string = "undefined";

  private readonly statusses: string[] = [
    StatusEnum.Requested,
    StatusEnum.Invited,
    StatusEnum.Customer,
    StatusEnum.DroppedOut,
  ];
  private selectedRows: IPostalCodeRequest[] = [];

  public constructor(props: IPostalCodeRequestProps) {
    super(props);

    const state: IPostalCodeRequestState = {
      isLoading: true,
      showDeleteModal: false,
      showSendEmailModal: false,
      showAddWaitersPopup: false,

      patchPostalCodeRequestsLoading: false,
    };

    this.state = state;

    // Bindings
    this.updateStatus = this.updateStatus.bind(this);
    this.onDataChangeSuccess = this.onDataChangeSuccess.bind(this);
    this.onZipStartChange = this.onZipStartChange.bind(this);
    this.onZipEndChange = this.onZipEndChange.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.onChangeDateStart = this.onChangeDateStart.bind(this);
    this.onChangeDateEnd = this.onChangeDateEnd.bind(this);
    this.setSelectedRows = this.setSelectedRows.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.bulkSetStatusInvited = this.bulkSetStatusInvited.bind(this);
    // addwaiterBindings
    this.newPostalCodeRequestNameChanged = this.newPostalCodeRequestNameChanged.bind(this);
    this.newPostalCodeRequestPostalCodeChanged = this.newPostalCodeRequestPostalCodeChanged.bind(this);
    this.newPostalCodeRequestHouseNumberChanged = this.newPostalCodeRequestHouseNumberChanged.bind(this);
    this.newPostalCodeRequestCityChanged = this.newPostalCodeRequestCityChanged.bind(this);
    this.newPostalCodeRequestEmailAddressChanged = this.newPostalCodeRequestEmailAddressChanged.bind(this);
    this.newPostalCodeRequestPhoneNumberChanged = this.newPostalCodeRequestPhoneNumberChanged.bind(this);
    this.newPostalCodeRequestRequestedTimeChanged = this.newPostalCodeRequestRequestedTimeChanged.bind(this);
    this.newPostalCodeRequestRequestedDateTimeChanged = this.newPostalCodeRequestRequestedDateTimeChanged.bind(this);
    this.newPostalCodeRequestZizoCodeChanged = this.newPostalCodeRequestZizoCodeChanged.bind(this);
  }

  public componentDidMount(): void {
    this.props.onFetchPostalCodeRequests("");

    // Set request date to current date
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    this.setState((prevState) => ({
      newPostalCodeRequest: { ...prevState.newPostalCodeRequest, requestDateTime: formattedDate },
    }));
  }

  private onDelete(requestId: string | undefined): void {
    if (requestId !== undefined) {
      this.props.onDeletePostalCodeRequest(requestId, this.onDataChangeSuccess);
    }

    this.showDeleteModal(false, undefined);
  }

  private toggleShowAddWaitersPopup(show: boolean): void {
    this.setState({
      showAddWaitersPopup: show,
    });
  }

  private validateNewWaitersEntry(): void {
    const validateUpsertModal = this.validateUpsertModal();
    if (validateUpsertModal === true) {
      this.completeAddWaiterUpsert();
    } else if (validateUpsertModal === false) {
      NotificationManager.error(
        LanguageProvider.t(TranslationMapper.pages.notification.messages.error.invalid_values),
        "",
        9999
      );
    }
  }

  private completeAddWaiterUpsert(): void {
    const newPostalCodeRequest = {
      ...this.state.newPostalCodeRequest,
      // generate new id for the new waiter
      postalCodeRequestId: crypto.randomUUID(),
      // set ironingtime to 0
      requestedironingTime: 0,
      // set status Aangevraagd
      status: StatusEnum.Requested.toString(),
    };

    this.props.onUpsertPostalCodeRequest(newPostalCodeRequest, () => {
      // refresh the waiters list
      this.onFetchPostalCodeRequestsWithFilter();
      // close popup
      this.toggleShowAddWaitersPopup(false);
      // reset state
      this.setState({ newPostalCodeRequest: undefined });
      // notify user waiter is successfully added
      NotificationManager.success(LanguageProvider.t(TranslationMapper.pages.waiters.request_update_success));
    });
  }

  //   validate new waiter entry
  private validateUpsertModal(): boolean {
    // Postal code is required and validate on Dutch postal code
    if (
      this.state.newPostalCodeRequest?.postalCode === undefined ||
      !InputValidator.isZipCodeValid(this.state.newPostalCodeRequest?.postalCode)
    ) {
      return false;
    }

    // City is required
    if (this.state.newPostalCodeRequest?.city === undefined) {
      return false;
    }

    // Name is required
    if (this.state.newPostalCodeRequest?.name === undefined) {
      return false;
    }

    // Requested datetime is required
    if (this.state.newPostalCodeRequest?.requestDateTime === undefined) {
      return false;
    }

    // Set status to requested
    this.setState((prevState) => ({
      newPostalCodeRequest: {
        ...prevState.newPostalCodeRequest,
        status: StatusEnum.Requested,
      },
    }));

    // Round hours to 1 decimal place
    if (this.state.newPostalCodeRequest?.requestedTime !== undefined) {
      this.setState((prevState) => ({
        newPostalCodeRequest: {
          ...prevState.newPostalCodeRequest,
          requestedTime: parseFloat(Number(prevState.newPostalCodeRequest?.requestedTime).toFixed(1)),
        },
      }));
    }

    // Email is required and validate
    if (
      this.state.newPostalCodeRequest?.emailAddress === undefined ||
      !InputValidator.isEmailAddressValid(this.state.newPostalCodeRequest?.emailAddress)
    ) {
      return false;
    }

    // Phone number is required and valid
    if (
      this.state.newPostalCodeRequest?.phoneNumber === undefined ||
      !InputValidator.isPhoneNumberValid(this.state.newPostalCodeRequest?.phoneNumber)
    ) {
      return false;
    }

    // House number is required
    if (this.state.newPostalCodeRequest?.houseNumber === undefined) {
      return false;
    }

    return true;
  }

  private sendEmail(): void {
    if (this.selectedRows.length > 0) {
      const postalCodeActivatedEmail: IPostalCodeActivatedEmail = {
        toAddresses: [],
      };
      const postalCodeRequestsToPatch: IPatchPostalCodeRequest[] = [];

      for (const row of this.selectedRows) {
        const emailAddress: IEmailAddress = {
          name: row.name ?? "",
          email: row.emailAddress ?? "",
        };
        const postalCodeRequest: IPatchPostalCodeRequest = {
          postalCodeRequestId: row.postalCodeRequestId,
        };
        postalCodeActivatedEmail.toAddresses.push(emailAddress);
        postalCodeRequestsToPatch.push(postalCodeRequest);
      }

      this.props.onSendEmail(postalCodeActivatedEmail, () => this.bulkSetStatusInvited(postalCodeRequestsToPatch));
    }
  }

  private bulkSetStatusInvited(requests: IPatchPostalCodeRequest[]): void {
    this.props.onPatchPostalCodeRequests(requests, StatusEnum.Invited, this.onDataChangeSuccess, () =>
      this.showSendEmailModal(false)
    );
  }

  private updateStatus(id: string, status: string): void {
    this.props.onPatchPostalCodeRequest(id, status, this.onDataChangeSuccess);
  }

  private onDataChangeSuccess(): void {
    this.onFetchPostalCodeRequestsWithFilter();
  }

  private setSelectedRows(data: object[]): void {
    const selectedItems: IPostalCodeRequest[] = data as IPostalCodeRequest[];
    this.selectedRows = selectedItems;
  }

  private newPostalCodeRequestNameChanged(event: ChangeEvent<HTMLInputElement>): void {
    const value = event?.target.value;

    this.setState((prevState) => ({
      newPostalCodeRequest: { ...prevState.newPostalCodeRequest, name: value },
    }));
  }

  private newPostalCodeRequestPostalCodeChanged(event: ChangeEvent<HTMLInputElement>): void {
    // Remove spaces from postal code
    const value = event?.target.value.replace(" ", "");

    this.setState((prevState) => ({
      newPostalCodeRequest: { ...prevState.newPostalCodeRequest, postalCode: value },
    }));
  }

  private newPostalCodeRequestHouseNumberChanged(event: ChangeEvent<HTMLInputElement>): void {
    const value = Number(event?.target.value.substring(0, 6)) || undefined;

    this.setState((prevState) => ({
      newPostalCodeRequest: { ...prevState.newPostalCodeRequest, houseNumber: value },
    }));
  }

  private newPostalCodeRequestCityChanged(event: ChangeEvent<HTMLInputElement>): void {
    const value = event?.target.value;

    this.setState((prevState) => ({
      newPostalCodeRequest: { ...prevState.newPostalCodeRequest, city: value },
    }));
  }

  private newPostalCodeRequestEmailAddressChanged(event: ChangeEvent<HTMLInputElement>): void {
    const value = event?.target.value;

    this.setState((prevState) => ({
      newPostalCodeRequest: { ...prevState.newPostalCodeRequest, emailAddress: value },
    }));
  }

  private newPostalCodeRequestPhoneNumberChanged(event: ChangeEvent<HTMLInputElement>): void {
    const value = event?.target.value;
    this.setState((prevState) => ({
      newPostalCodeRequest: { ...prevState.newPostalCodeRequest, phoneNumber: value },
    }));
  }

  private newPostalCodeRequestRequestedTimeChanged(event: ChangeEvent<HTMLInputElement>): void {
    const value = Number(event?.target.value) || undefined;

    this.setState((prevState) => ({
      newPostalCodeRequest: { ...prevState.newPostalCodeRequest, requestedTime: value },
    }));
  }

  private newPostalCodeRequestRequestedDateTimeChanged(event: ChangeEvent<HTMLInputElement>): void {
    const value = event?.target.value;

    this.setState((prevState) => ({
      newPostalCodeRequest: { ...prevState.newPostalCodeRequest, requestDateTime: value },
    }));
  }

  private newPostalCodeRequestZizoCodeChanged(event: ChangeEvent<HTMLInputElement>): void {
    const value = event?.target.value;

    this.setState((prevState) => ({
      newPostalCodeRequest: { ...prevState.newPostalCodeRequest, zizoCode: value },
    }));
  }
  private renderRowSubComponent(rowData: ReactTableRow): JSX.Element {
    const postalCodeRequestDetails = rowData.original as IPostalCodeRequest;
    return (
      <div className="postalcode-request-table-row-sub">
        <Container fluid>
          <Row>
            <Col md className="postalcode-request-table-col">
              <Col md>
                <b>{LanguageProvider.t(TranslationMapper.components.labels.label_email)}</b>
              </Col>
              <Col md>{postalCodeRequestDetails.emailAddress}</Col>
            </Col>
            <Col md className="postalcode-request-table-col">
              <Col md>
                <b>{LanguageProvider.t(TranslationMapper.pages.waiters.label_phone)}</b>
              </Col>
              <Col md>{postalCodeRequestDetails.phoneNumber}</Col>
            </Col>
            <Col md className="postalcode-request-table-col">
              <Col md>
                <b>{LanguageProvider.t(TranslationMapper.components.labels.column_zizocode)}</b>
              </Col>
              <Col md>{postalCodeRequestDetails.zizoCode}</Col>
            </Col>
            <Col md className="postalcode-request-table-col">
              <Col md>
                <b>{LanguageProvider.t(TranslationMapper.components.labels.label_invitation_send_date)}</b>
              </Col>
              {postalCodeRequestDetails.invitedAt !== undefined && postalCodeRequestDetails.invitedAt !== null && (
                <Col md>
                  {DateUtils.getFriendlyDateTimeStringWithYear(
                    DateUtils.utcToLocal(postalCodeRequestDetails.invitedAt)
                  )}
                </Col>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  private get getColumns(): Column[] {
    const onUpdateStatus = (id: string, e: ChangeEvent<HTMLInputElement>): void =>
      this.updateStatus(id, e.target.value.trim());
    const options = this.statusses.map((status) => (
      <option value={status} key={status}>
        {status}
      </option>
    ));

    const columns: Column[] = [
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_zipcode),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.postalCode),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_city),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.city),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_housenumber),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.houseNumber),
      },

      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_name),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.name),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_request_date),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.requestDateTime),
        Cell: ({ value }): string => DateUtils.getFriendlyDateTimeStringWithYear(DateUtils.utcToLocal(value)),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_status),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.status),
        Cell: ({ value, row }): JSX.Element => {
          return (
            <>
              <Form.Control
                as="select"
                defaultValue={value}
                onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                  onUpdateStatus(row.values.postalCodeRequestId.toString(), e)
                }
              >
                {options}
              </Form.Control>
            </>
          );
        },
      },
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_hours_per_period),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.totalTime),
      },
      {
        Header: LanguageProvider.t(TranslationMapper.components.labels.column_total_costs),
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.totalCosts),
      },
      {
        Header: (): null => null,
        accessor: ReactTableDataUtil.getPropertyNameAsAccessor(
          this.tableDataExampleObject,
          (x) => x.postalCodeRequestId
        ),
        Cell: ({ row }): JSX.Element => {
          return (
            <div className="g-react-table__actions">
              <div>
                <img
                  src={DeleteIcon}
                  alt="delete postalcode request"
                  onClick={(): void => this.showDeleteModal(true, row.values.postalCodeRequestId)}
                  className="g-react-table__action"
                />
              </div>
              <div>
                <span {...row.getToggleRowExpandedProps()}>
                  <div
                    className={
                      row.isExpanded ? "g-react-table__row-expander-open" : "g-react-table__row-expander-closed"
                    }
                  />
                </span>
              </div>
            </div>
          );
        },
        disableSortBy: true,
      },
    ];
    return columns;
  }

  private showDeleteModal(doShow: boolean, requestId: string | undefined): void {
    this.setState({
      postalCodeRequestToDelete: requestId,
      showDeleteModal: doShow,
    });
  }

  private showSendEmailModal(showModal: boolean): void {
    this.setState({
      showSendEmailModal: showModal,
    });
  }

  private showAddWaitersModal(showModal: boolean): void {
    this.setState({
      showAddWaitersPopup: showModal,
    });
  }

  private getAndTrimSelection(e: ChangeEvent<HTMLInputElement>): string | undefined {
    const value = e.target.value.trim();
    const selection = value !== this.filterResetValue && value !== "" ? value : undefined;
    return selection;
  }

  private onZipStartChange(e: ChangeEvent<HTMLInputElement>): void {
    this.setState(
      {
        selectedZipCodeRangeStart: this.getAndTrimSelection(e),
      },
      this.onFetchPostalCodeRequestsWithFilter
    );
  }

  private onZipEndChange(e: ChangeEvent<HTMLInputElement>): void {
    this.setState(
      {
        selectedZipCodeRangeEnd: this.getAndTrimSelection(e),
      },
      this.onFetchPostalCodeRequestsWithFilter
    );
  }

  private onStatusChange(e: ChangeEvent<HTMLInputElement>): void {
    this.setState(
      {
        selectedStatus: this.getAndTrimSelection(e),
      },
      this.onFetchPostalCodeRequestsWithFilter
    );
  }

  private onChangeDateStart(e: ChangeEvent<HTMLInputElement>): void {
    this.setState(
      {
        selectedDateStart: this.getAndTrimSelection(e),
      },
      this.onFetchPostalCodeRequestsWithFilter
    );
  }

  private onChangeDateEnd(e: ChangeEvent<HTMLInputElement>): void {
    this.setState(
      {
        selectedDateEnd: this.getAndTrimSelection(e),
      },
      this.onFetchPostalCodeRequestsWithFilter
    );
  }

  private onFetchPostalCodeRequestsWithFilter(): void {
    let filter = "";
    if (this.state.selectedStatus !== undefined) {
      filter = filter.concat(`?&filter=Status eq '${this.state.selectedStatus}'`);
    }

    if (this.state.selectedZipCodeRangeStart !== undefined) {
      if (filter !== "") {
        filter = filter.concat(` and PostalCode ge '${this.state.selectedZipCodeRangeStart}'`);
      } else {
        filter = filter.concat(`?&filter=PostalCode ge '${this.state.selectedZipCodeRangeStart}'`);
      }
    }

    if (this.state.selectedZipCodeRangeEnd !== undefined) {
      if (filter !== "") {
        filter = filter.concat(
          ` and PostalCode le '${this.state.selectedZipCodeRangeEnd}${
            this.state.selectedZipCodeRangeEnd.length === 4 ? "ZZ" : ""
          }'`
        );
      } else {
        filter = filter.concat(
          `?&filter=PostalCode le '${this.state.selectedZipCodeRangeEnd}${
            this.state.selectedZipCodeRangeEnd.length === 4 ? "ZZ" : ""
          }'`
        );
      }
    }

    if (this.state.selectedDateStart !== undefined) {
      if (filter !== "") {
        filter = filter.concat(` and RequestDateTime ge ${this.getOdataDateFormat(this.state.selectedDateStart)}`);
      } else {
        filter = filter.concat(`?&filter=RequestDateTime ge ${this.getOdataDateFormat(this.state.selectedDateStart)}`);
      }
    }

    if (this.state.selectedDateEnd !== undefined) {
      if (filter !== "") {
        filter = filter.concat(` and RequestDateTime le ${this.getOdataDateFormat(this.state.selectedDateEnd)}`);
      } else {
        filter = filter.concat(`?&filter=RequestDateTime le ${this.getOdataDateFormat(this.state.selectedDateEnd)}`);
      }
    }
    this.props.onFetchPostalCodeRequests(filter);
  }

  private getOdataDateFormat(date: string): string {
    return date + "T00:00.00Z";
  }

  private renderFooter(): JSX.Element {
    return (
      <div className="d-flex justify-content-between w-100">
        <Button
          style={ButtonStyle.blueOutline}
          onClick={(): void => this.toggleShowAddWaitersPopup(false)}
          label={LanguageProvider.t(TranslationMapper.global.buttons.cancel)}
        />
        <Button
          style={ButtonStyle.blue}
          onClick={(): void => this.validateNewWaitersEntry()}
          label={LanguageProvider.t(TranslationMapper.global.buttons.ok)}
        />
      </div>
    );
  }

  public render(): JSX.Element {
    const options = this.statusses.map((status) => (
      <option value={status} key={status}>
        {status}
      </option>
    ));

    return (
      <Page className="postalcode-request-page">
        <>
          <div className="react-table__top">
            <div className="filters">
              <div className="grouped-filter">
                <div className="grouped-filter-label">
                  {LanguageProvider.t(TranslationMapper.components.labels.column_status)}
                </div>
                <Form.Control as="select" value={this.state.selectedStatus ?? ""} onChange={this.onStatusChange}>
                  <option value={this.filterResetValue}>
                    {LanguageProvider.t(TranslationMapper.pages.waiters.status_filter_placeholder)}
                  </option>
                  {options}
                </Form.Control>
              </div>
              <div className="grouped-filter">
                <div className="grouped-filter-label">
                  {LanguageProvider.t(TranslationMapper.components.labels.column_postalcode)}
                </div>
                <Form.Control
                  type="text"
                  value={this.state.selectedZipCodeRangeStart ?? ""}
                  onChange={this.onZipStartChange}
                  maxLength={4}
                  placeholder={LanguageProvider.t(TranslationMapper.pages.waiters.postalcode_from_filter_placeholder)}
                ></Form.Control>
                <Form.Control
                  type="text"
                  value={this.state.selectedZipCodeRangeEnd ?? ""}
                  onChange={this.onZipEndChange}
                  maxLength={4}
                  placeholder={LanguageProvider.t(TranslationMapper.pages.waiters.postalcode_till_filter_placeholder)}
                ></Form.Control>
              </div>
              <div className="grouped-filter">
                <div className="grouped-filter-label">
                  {LanguageProvider.t(TranslationMapper.components.labels.column_request_date)}
                </div>
                <Form.Control
                  type="text"
                  value={this.state.selectedDateStart ?? ""}
                  onChange={this.onChangeDateStart}
                  onFocus={(e): string => (e.target.type = "date")}
                  onBlur={(e): string => (e.target.type = "text")}
                  placeholder={LanguageProvider.t(TranslationMapper.pages.waiters.request_date_filter_from_placeholder)}
                ></Form.Control>
                <Form.Control
                  type="text"
                  value={this.state.selectedDateEnd ?? ""}
                  onChange={this.onChangeDateEnd}
                  onFocus={(e): string => (e.target.type = "date")}
                  onBlur={(e): string => (e.target.type = "text")}
                  placeholder={LanguageProvider.t(TranslationMapper.pages.waiters.request_date_filter_till_placeholder)}
                ></Form.Control>
              </div>
              {this.props.isLoading && (
                <div className="spinner-border mb-2" role="status">
                  <span className="sr-only"></span>
                </div>
              )}
            </div>
            <div>
              <Button
                style={ButtonStyle.blue}
                label={LanguageProvider.t(TranslationMapper.global.buttons.send)}
                onClick={(): void => this.showSendEmailModal(true)}
                className="me-3"
              />
              <Button
                style={ButtonStyle.blue}
                label={LanguageProvider.t(TranslationMapper.global.buttons.create)}
                onClick={(): void => this.showAddWaitersModal(true)}
              />
            </div>
          </div>
          <ReactTable
            isSelectable={true}
            setSelection={this.setSelectedRows}
            columns={this.getColumns}
            data={this.props.postalCodeRequests ?? []}
            renderRowSubComponent={this.renderRowSubComponent}
            className="postalcode-request-table"
            usePaginationForTable={true}
            defaultColumnToSortBy={{
              id: ReactTableDataUtil.getPropertyNameAsAccessor(this.tableDataExampleObject, (x) => x.requestDateTime),
              desc: true,
            }}
          />

          <ConfirmationPrompt
            showPrompt={this.state.showDeleteModal}
            onCancel={(): void => this.showDeleteModal(false, undefined)}
            onAgree={(): void => this.onDelete(this.state.postalCodeRequestToDelete)}
          />

          {/* AddWaitersPopup */}
          <BasicPopup
            showPopup={this.state.showAddWaitersPopup}
            onClose={(): void => this.toggleShowAddWaitersPopup(false)}
            headerText={LanguageProvider.t(TranslationMapper.pages.waiters.popup.title)}
            footerContent={this.renderFooter()}
            className="g-basic-popup-big"
          >
            <div className="mx-3">
              <Row>
                <Col md={6}>
                  <div className="grouped-filter mb-3">
                    <div className="grouped-filter-label">
                      {LanguageProvider.t(TranslationMapper.pages.waiters.popup.name)}*{/* verplicht */}
                    </div>
                    <Form.Control
                      type="text"
                      value={this.state.newPostalCodeRequest?.name ?? ""}
                      onChange={this.newPostalCodeRequestNameChanged}
                      maxLength={50}
                      placeholder={LanguageProvider.t(TranslationMapper.pages.waiters.popup.name)}
                    ></Form.Control>
                    <div className="form-text">
                      {LanguageProvider.t(TranslationMapper.pages.max_length.max_length_255)}
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="grouped-filter mb-3">
                    <div className="grouped-filter-label">
                      {LanguageProvider.t(TranslationMapper.pages.waiters.popup.requested_date_time)}*{/* verplicht */}
                    </div>
                    <Form.Control
                      type="date"
                      value={this.state.newPostalCodeRequest?.requestDateTime ?? ""}
                      onChange={this.newPostalCodeRequestRequestedDateTimeChanged}
                      maxLength={7}
                      placeholder={LanguageProvider.t(TranslationMapper.pages.waiters.popup.requested_date_time)}
                    ></Form.Control>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="grouped-filter mb-3">
                    <div className="grouped-filter-label">
                      {LanguageProvider.t(TranslationMapper.pages.waiters.popup.email_address)}*{/* verplicht */}
                    </div>
                    <Form.Control
                      type="text"
                      value={this.state.newPostalCodeRequest?.emailAddress ?? ""}
                      onChange={this.newPostalCodeRequestEmailAddressChanged}
                      maxLength={255}
                      placeholder={LanguageProvider.t(TranslationMapper.pages.waiters.popup.email_address)}
                    ></Form.Control>
                    <div className="form-text">
                      {LanguageProvider.t(TranslationMapper.pages.max_length.max_length_255)}
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="grouped-filter mb-3">
                    <div className="grouped-filter-label">
                      {LanguageProvider.t(TranslationMapper.pages.waiters.popup.phone_number)}*{/* verplicht */}
                    </div>
                    <Form.Control
                      type="number"
                      value={this.state.newPostalCodeRequest?.phoneNumber ?? ""}
                      onChange={this.newPostalCodeRequestPhoneNumberChanged}
                      maxLength={255}
                      placeholder={LanguageProvider.t(TranslationMapper.pages.waiters.popup.phone_number)}
                    ></Form.Control>
                    <div className="form-text">
                      {LanguageProvider.t(TranslationMapper.pages.max_length.max_length_255)}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="grouped-filter mb-3">
                    <div className="grouped-filter-label">
                      {LanguageProvider.t(TranslationMapper.pages.waiters.popup.postal_code)}*{/* verplicht */}
                    </div>
                    <Form.Control
                      type="text"
                      value={this.state.newPostalCodeRequest?.postalCode ?? ""}
                      onChange={this.newPostalCodeRequestPostalCodeChanged}
                      maxLength={6}
                      placeholder={LanguageProvider.t(TranslationMapper.pages.waiters.popup.postal_code)}
                    ></Form.Control>
                    <div className="form-text">
                      {LanguageProvider.t(TranslationMapper.pages.max_length.max_length_6)}
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="grouped-filter mb-3">
                    <div className="grouped-filter-label">
                      {LanguageProvider.t(TranslationMapper.pages.waiters.popup.city)}*{/* verplicht */}
                    </div>
                    <Form.Control
                      type="text"
                      value={this.state.newPostalCodeRequest?.city ?? ""}
                      onChange={this.newPostalCodeRequestCityChanged}
                      maxLength={255}
                      placeholder={LanguageProvider.t(TranslationMapper.pages.waiters.popup.city)}
                    ></Form.Control>
                    <div className="form-text">
                      {LanguageProvider.t(TranslationMapper.pages.max_length.max_length_255)}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <div className="grouped-filter mb-3">
                    <div className="grouped-filter-label">
                      {LanguageProvider.t(TranslationMapper.pages.waiters.popup.house_number)}*{/* verplicht */}
                    </div>
                    <Form.Control
                      type="number"
                      value={this.state.newPostalCodeRequest?.houseNumber ?? ""}
                      onChange={this.newPostalCodeRequestHouseNumberChanged}
                      maxLength={6}
                      placeholder={LanguageProvider.t(TranslationMapper.pages.waiters.popup.house_number)}
                    ></Form.Control>
                    <div className="form-text">
                      {LanguageProvider.t(TranslationMapper.pages.max_length.max_length_6)}
                    </div>
                  </div>
                </Col>
                <Col md={6}></Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="grouped-filter mb-3">
                    <div className="grouped-filter-label">
                      {LanguageProvider.t(TranslationMapper.pages.waiters.popup.requested_time)}
                    </div>
                    <Form.Control
                      type="number"
                      step={0.1}
                      value={this.state.newPostalCodeRequest?.requestedTime ?? ""}
                      onChange={this.newPostalCodeRequestRequestedTimeChanged}
                      maxLength={7}
                      placeholder={LanguageProvider.t(TranslationMapper.pages.waiters.popup.requested_time)}
                    ></Form.Control>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="grouped-filter mb-3">
                    <div className="grouped-filter-label">
                      {LanguageProvider.t(TranslationMapper.pages.waiters.popup.zizo_code)}
                    </div>
                    <Form.Control
                      type="text"
                      value={this.state.newPostalCodeRequest?.zizoCode ?? ""}
                      onChange={this.newPostalCodeRequestZizoCodeChanged}
                      maxLength={255}
                      placeholder={LanguageProvider.t(TranslationMapper.pages.waiters.popup.zizo_code)}
                    ></Form.Control>
                    <div className="form-text">
                      {LanguageProvider.t(TranslationMapper.pages.max_length.max_length_255)}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </BasicPopup>

          {/* SendEmailPopup */}
          <BasicPopup
            showPopup={this.state.showSendEmailModal}
            onClose={(): void => this.showSendEmailModal(false)}
            headerText={
              this.selectedRows.length > 0
                ? LanguageProvider.t(TranslationMapper.global.messages.send_postalcode_email_confirmation).replace(
                    "{0}",
                    this.selectedRows.length.toString()
                  )
                : LanguageProvider.t(TranslationMapper.global.errors.no_waiter_selected)
            }
          >
            <Container className="bt-1">
              <Row className="justify-content-around mt-4">
                {this.selectedRows.length > 0 && !this.props.sendEmailIsLoading && (
                  <Button
                    style={ButtonStyle.pinkGradient}
                    label={LanguageProvider.t(TranslationMapper.global.buttons.send)}
                    onClick={(): void => this.sendEmail()}
                  />
                )}
                {this.selectedRows.length === 0 && !this.props.patchPostalCodeRequestsLoading && (
                  <Button
                    style={ButtonStyle.red}
                    label={LanguageProvider.t(TranslationMapper.global.buttons.cancel)}
                    onClick={(): void => this.showSendEmailModal(false)}
                  />
                )}
                {(this.props.sendEmailIsLoading || this.props.patchPostalCodeRequestsLoading) && (
                  <Container>
                    <Row className="justify-content-center">
                      <output className="spinner-border mb-2">
                        <span className="sr-only"></span>
                      </output>
                    </Row>
                    <Row className="justify-content-center">
                      <span>{this.props.sendEmailIsLoading ? "Versturen e-mails..." : "Statussen aanpassen..."}</span>
                    </Row>
                  </Container>
                )}
              </Row>
            </Container>
          </BasicPopup>
        </>
      </Page>
    );
  }
}

const mapStateToProps = (state: RootState): IPostalCodeRequestStateProps => ({
  postalCodeRequests: state.postalCodeRequestState.fetchedPostalCodeRequests,
  isLoading: state.postalCodeRequestState.isLoading,
  sendEmailIsLoading: state.postalCodeRequestState.sendEmailIsLoading,
  patchPostalCodeRequestsLoading: state.postalCodeRequestState.patchPostalCodeRequestsLoading,
});

const mapDispatchToProps: IPostalCodeRequestDispatchProps = {
  onFetchPostalCodeRequests: fetchPostalCodeRequests,
  onPatchPostalCodeRequest: patchPostalCodeRequest,
  onDeletePostalCodeRequest: deletePostalCodeRequest,
  onSendEmail: sendEmail,
  onPatchPostalCodeRequests: patchPostalCodeRequests,
  onUpsertPostalCodeRequest: upsertPostalCodeRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostalCodeRequest);
