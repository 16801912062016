import { AuthApiAuthenticator } from "authenticators/authApiAuthenticator";
import INotification from "interfaces/INotification";
import IPostalCodeRequest from "interfaces/IPostalCodeRequest";
import IProjectWorkProgram from "interfaces/IProjectWorkProgram";
import IRoomTypeWorkProgram from "interfaces/IRoomTypeWorkProgram";
import IUnitPrice from "interfaces/IUnitPrice";
import IWorkProgramAction from "interfaces/IWorkProgramAction";
import IWorkProgramActionFrequency from "interfaces/IWorkProgramActionFrequency";
import { IPatchPostalCodeRequest } from "pages/postalCodeRequest/interfaces/IPatchPostalCodeRequest";
import { ApplicationConfig } from "../config";
import IActiveRegion from "../interfaces/IActiveRegion";
import IUpsertActiveReqionRequest from "../interfaces/IUpsertActiveReqionRequest";
import BaseClient from "./baseClient";

export default class ManagementClient extends BaseClient {
  private readonly regionEndpoint: string = "region";
  private readonly postalCodeRequestEndpoint: string = "postalCodeRequest";
  private readonly workProgramEndpoint: string = "workProgram";
  private readonly unitPriceEndpoint: string = "unitPrice";
  private readonly notificationEndpoint: string = "NotificationItems";

  public constructor() {
    super(ApplicationConfig.endpoints.managementApi, new AuthApiAuthenticator());
  }

  public async getActiveRegionsAsync(): Promise<IActiveRegion[]> {
    return this.get<IActiveRegion[]>(`${this.regionEndpoint}/activeRegions`);
  }

  public async deleteActiveRegionAsync(region: number): Promise<void> {
    await this.delete(`${this.regionEndpoint}/activeRegions/${region}`);
  }

  public async upsertActiveRegionAsync(request: IUpsertActiveReqionRequest): Promise<void> {
    await this.post(`${this.regionEndpoint}/activeRegions`, request);
  }

  public async getPostalCodeRequestsAsync(filter: string): Promise<IPostalCodeRequest[]> {
    return this.get<IPostalCodeRequest[]>(`${this.postalCodeRequestEndpoint}${filter}`);
  }

  public async patchPostalCodeRequestAsync(id: string, status: string): Promise<Response> {
    return this.patch(`${this.postalCodeRequestEndpoint}/${id}/status/${status}`, undefined);
  }

  public async patchPostalCodeRequestsAsync(requests: IPatchPostalCodeRequest[], status: string): Promise<Response> {
    return this.patch(`${this.postalCodeRequestEndpoint}/status/${status}`, requests);
  }

  public async upsertPostalCodeRequestAsync(postalCodeRequest: IPostalCodeRequest): Promise<Response> {
    return this.post(`${this.postalCodeRequestEndpoint}/serviceportal`, postalCodeRequest);
  }

  public async deletePostalCodeRequestAsync(id: string): Promise<Response> {
    return this.delete(`${this.postalCodeRequestEndpoint}/${id}`);
  }

  public async getWorkProgramAsync(externalProjectId: string | undefined): Promise<IProjectWorkProgram> {
    return this.get(`${this.workProgramEndpoint}/serviceportal/${externalProjectId ?? ""}`);
  }

  public async createProjectWorkProgramAsync(externalProjectId: string): Promise<IProjectWorkProgram> {
    return this.postWithResponse(`${this.workProgramEndpoint}/${externalProjectId}`, externalProjectId);
  }

  public async getActionFrequenciesAsync(): Promise<IWorkProgramActionFrequency[]> {
    return this.get(`${this.workProgramEndpoint}/actionFrequencies`);
  }

  public async removeActionAsync(action: IWorkProgramAction): Promise<Response> {
    return this.delete(`${this.workProgramEndpoint}/action/${action.actionId}`);
  }

  public async addActionAsync(action: IWorkProgramAction): Promise<IWorkProgramAction> {
    return this.postWithResponse(`${this.workProgramEndpoint}/action`, action);
  }

  public async updateActionAsync(action: IWorkProgramAction): Promise<Response> {
    return this.put(`${this.workProgramEndpoint}/action`, action);
  }

  public async addRoomAsync(room: IRoomTypeWorkProgram): Promise<IRoomTypeWorkProgram> {
    return this.postWithResponse(`${this.workProgramEndpoint}/room`, room);
  }

  public async removeRoomAsync(room: IRoomTypeWorkProgram): Promise<Response> {
    return this.delete(`${this.workProgramEndpoint}/room/${room.roomTypeWorkProgramId}`);
  }

  public async getAllUnitPricesAsync(): Promise<IUnitPrice[]> {
    return this.get(`${this.unitPriceEndpoint}/getAllUnitPrices`);
  }

  public async upsertUnitPriceAsync(unitPrice: IUnitPrice): Promise<void> {
    await this.post(`${this.unitPriceEndpoint}/upsertUnitPrice`, unitPrice);
  }

  public async deleteUnitPriceAsync(id: string): Promise<Response> {
    return this.delete(`${this.unitPriceEndpoint}/deleteUnitPrice/${id}`);
  }

  public async deleteNotificationAsync(id: string): Promise<any> {
    return this.post(`${this.notificationEndpoint}/Delete`, { id: id });
  }

  public async getAllNotificationsAsync(): Promise<any> {
    return this.get(`${this.notificationEndpoint}/ListAll`);
  }

  public async upsertNotificationAsync(notification: INotification): Promise<any> {
    return this.post(`${this.notificationEndpoint}/Upsert`, notification);
  }
}
