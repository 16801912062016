export function replaceStringPlaceholders(str: string, ...val: string[]): string {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index]);
  }
  return str;
}

export function getHtmlSafeString(string: string): string {
  // Strip all unwanted chars so it can be safely use as html attribute
  return string.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");
}

export function isNullOrEmpty(string: string | undefined): boolean {
  return string == null || string.trim() === "";
}
