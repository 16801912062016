/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ValidationTypes } from "enums/validationTypes";
import TranslationMapper from "i18n/mapper";
import IPostalCodeActivatedEmail from "interfaces/IPostalCodeActivatedEmail";
import IPostalCodeRequest from "interfaces/IPostalCodeRequest";
import { IPatchPostalCodeRequest } from "pages/postalCodeRequest/interfaces/IPatchPostalCodeRequest";
import LanguageProvider from "providers/languageProvider";
import { NotificationManager } from "react-notifications";
import AccountService from "services/accountService";
import ManagementService from "services/managementService";
import TelemetryService from "services/telemetryService";
import { ActionTypes } from "store/actionTypes";
import { actionCreator, AppAction } from "store/appAction";

export const setPostalCodeRequestLoading = actionCreator<ActionTypes.UPDATE_LOADING_POSTAL_CODE_REQUEST, boolean>(
  ActionTypes.UPDATE_LOADING_POSTAL_CODE_REQUEST
);
export const setPatchingPostalCodeRequestStatusLoading = actionCreator<
  ActionTypes.UPDATE_PATCH_POSTAL_CODE_REQUEST_STATUS,
  boolean
>(ActionTypes.UPDATE_PATCH_POSTAL_CODE_REQUEST_STATUS);
export const setSendEmailIsLoading = actionCreator<ActionTypes.UPDATE_LOADING_SEND_EMAIL, boolean>(
  ActionTypes.UPDATE_LOADING_SEND_EMAIL
);
export const fetchedPostalCodeRequests = actionCreator<ActionTypes.FETCHED_POSTAL_CODE_REQUESTS, IPostalCodeRequest[]>(
  ActionTypes.FETCHED_POSTAL_CODE_REQUESTS
);

export function toggleLoadingStatus(isLoading: boolean): AppAction {
  return (dispatch): void => {
    dispatch(setPostalCodeRequestLoading(isLoading));
  };
}

export function toggleSendEmailLoadingStatus(isLoading: boolean): AppAction {
  return (dispatch): void => {
    dispatch(setSendEmailIsLoading(isLoading));
  };
}

export function togglePatchingStatusLoading(isLoading: boolean): AppAction {
  return (dispatch): void => {
    dispatch(setPatchingPostalCodeRequestStatusLoading(isLoading));
  };
}

export function fetchPostalCodeRequests(filter: string): AppAction {
  return (dispatch): void => {
    const managementService = new ManagementService();
    dispatch(toggleLoadingStatus(true));
    managementService
      .getPostalCodeRequestsAsync(filter)
      .then((postalCodeRequests) => {
        postalCodeRequests.forEach((req) => {
          req.totalCosts = (req.costEmployee ?? 0.0) + (req.costEmployer ?? 0.0);
          req.totalTime = (Number(req.requestedTime) ?? 0.0) + Number(req.requestedIroningTime ?? 0.0);
        });

        dispatch(fetchedPostalCodeRequests(postalCodeRequests));
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "PostalcodeRequestActions.fetchPostalCodeRequests",
            message: `Error on fetch postal code requests ${error}`,
          },
        });
        NotificationManager.error(
          LanguageProvider.t(TranslationMapper.global.errors.error_fetching_postal_code_request)
        );
      })
      .finally(() => dispatch(toggleLoadingStatus(false)));
  };
}

export function patchPostalCodeRequest(id: string, status: string, onSuccess: () => void): AppAction {
  return (dispatch): void => {
    const managementService = new ManagementService();
    dispatch(toggleLoadingStatus(true));
    managementService
      .patchPostalCodeRequestAsync(id, status)
      .then(() => {
        NotificationManager.success(
          LanguageProvider.t(TranslationMapper.pages.offer_request_management.status_update_success)
        );
        onSuccess();
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "PostalCodeRequestActions.updatePostalCodeRequest",
            message: `Error on update status '${id}', error: '${error}'`,
          },
        });
        NotificationManager.error(
          LanguageProvider.t(TranslationMapper.pages.offer_request_management.status_update_error)
        );
      })
      .finally(() => dispatch(toggleLoadingStatus(false)));
  };
}

// add new waiter
export function upsertPostalCodeRequest(postalCodeRequest: IPostalCodeRequest, onSuccess: () => void): AppAction {
  return (dispatch): void => {
    const managementService = new ManagementService();
    dispatch(togglePatchingStatusLoading(true));
    managementService
      .upsertPostalCodeRequestAsync(postalCodeRequest)
      .then((response: Response) => response.json())
      .then((response) => {
        if (response.propertyErrors) {
          response.propertyErrors.foreach((error) => {
            if (error.validationType === ValidationTypes.Unknown) {
              NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.waiters.request_update_error));
            }
          });
        } else {
          onSuccess();
        }
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "PostalCodeRequestActions.upsertPostalCodeRequest",
            message: `Error on upsert request '${postalCodeRequest.postalCodeRequestId}', error: '${error}'`,
          },
        });
        NotificationManager.error(LanguageProvider.t(TranslationMapper.pages.waiters.request_update_error));
      })
      .finally(() => {
        dispatch(togglePatchingStatusLoading(false));
      });
  };
}

export function patchPostalCodeRequests(
  requests: IPatchPostalCodeRequest[],
  status: string,
  onSuccess: () => void,
  onFinish: () => void
): AppAction {
  return (dispatch): void => {
    const managementService = new ManagementService();
    dispatch(togglePatchingStatusLoading(true));
    managementService
      .patchPostalCodeRequestsAsync(requests, status)
      .then(() => {
        NotificationManager.success(
          LanguageProvider.t(TranslationMapper.pages.offer_request_management.status_update_success)
        );
        onSuccess();
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "PostalCodeRequestActions.updatePostalCodeRequests",
            message: `Error on update status, error: '${error}'`,
          },
        });
        NotificationManager.error(
          LanguageProvider.t(TranslationMapper.pages.offer_request_management.status_update_error)
        );
      })
      .finally(() => {
        dispatch(togglePatchingStatusLoading(false));
        onFinish();
      });
  };
}

export function deletePostalCodeRequest(id: string, onSuccess: () => void): AppAction {
  return (dispatch): void => {
    const managementService = new ManagementService();
    dispatch(toggleLoadingStatus(true));
    managementService
      .deletePostalCodeRequestAsync(id)
      .then(() => {
        NotificationManager.success(
          LanguageProvider.t(TranslationMapper.pages.offer_request_management.postalcode_delete_success)
        );
        onSuccess();
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "PostalCodeRequestActions.deletePostalCodeRequest",
            message: `Error on delete request '${id}', error: '${error}'`,
          },
        });
        NotificationManager.error(
          LanguageProvider.t(TranslationMapper.pages.offer_request_management.error_postalcode_delete)
        );
      })
      .finally(() => dispatch(toggleLoadingStatus(false)));
  };
}

export function sendEmail(addresses: IPostalCodeActivatedEmail, onSuccess: () => void): AppAction {
  return (dispatch): void => {
    const accountService = new AccountService();
    dispatch(toggleSendEmailLoadingStatus(true));
    accountService
      .sendPostalcodeActivatedEmailAsync(addresses)
      .then(() => {
        NotificationManager.success(
          LanguageProvider.t(TranslationMapper.pages.offer_request_management.send_email_success)
        );
        onSuccess();
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "PostalCodeRequestActions.sendEmail",
            message: `Error on sending email, error: '${error}'`,
          },
        });
        NotificationManager.error(
          LanguageProvider.t(TranslationMapper.pages.offer_request_management.error_postalcode_delete)
        );
      })
      .finally(() => dispatch(toggleSendEmailLoadingStatus(false)));
  };
}
